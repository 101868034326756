import {HttpClient} from '../http-service/api';
import {API_LIST} from '../http-service/list';

export const GETUSERS = (payload) => {
    return HttpClient.get(API_LIST.GETUSERS,payload);
}
export const ADDUSER = (payload) => {
    return HttpClient.post(API_LIST.ADDUSER,payload);
}
export const UPDATEUSER = (payload) => {
    return HttpClient.post(API_LIST.UPDATEUSER,payload);
}
export const DELETEUSER = (payload) => {
   return HttpClient.post(API_LIST.DELETEUSER,payload);
}
export const GETREPORTS = (payload) => {
    return HttpClient.post(API_LIST.GETREPORTS,payload);
 }
export const GETCOURSES = (payload) => {
    return HttpClient.post(API_LIST.GETCOURSES,payload);
 }
export const ADDCOURSESTABLE = (payload) => {
    return HttpClient.post(API_LIST.ADDCOURSESTABLE,payload);
 }
export const GETCOURSESTABLE = (payload) => {
    return HttpClient.post(API_LIST.GETCOURSESTABLE,payload);
 }
export const UPDATECOURSE = (payload) => {
    return HttpClient.post(API_LIST.UPDATECOURSE,payload);
 }

 export const  ADDCOURSES = (payload) => {
    return HttpClient.post(API_LIST.ADDCOURSES,payload);
 }
 export const  GETSEATS = (payload) => {
    return HttpClient.post(API_LIST.GETSEATS,payload);
 }
 export const  DELETECOURSE = (payload) => {
    return HttpClient.post(API_LIST.DELETECOURSE,payload);
 }
 export const  DELETECOURSEMAPPING = (payload) => {
    return HttpClient.post(API_LIST.DELETECOURSEMAPPING,payload);
 }

 export const  EDITCOURSES = (payload) => {
   return HttpClient.post(API_LIST.EDITCOURSES,payload);
}