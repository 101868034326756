import React, { useState, useEffect, useCallback } from 'react';
import DocumentUpload from '../DocumentUpload.jsx';
import { SAVEINSTITUTEDATA, GETINSTITUTEDATA, DELETETABLEDATA, GETDROPDOWNDATA } from './ProfileServices.jsx';
import { useNavigate } from 'react-router-dom';
import "../../SuperAdmin/AffiliationSetupPage.css";
import { UNIVERSITY_CODE } from '../../../config';
import back from '../../../img/backarrow.png';
import Payment from '../PaymentComponents/Payment.jsx';
import deleteIcon from '../../../img/delete.png';
import CourseTable from '../Courses.jsx';


const TabbedFormRenderer = ({ jsonData, applicationId, application_status, last_page, affiliation_code }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [parentTab, setParentTab] = useState(0);
    const [formData, setFormData] = useState({});
    const [applicantId, setApplicantId] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [errors, setErrors] = useState({});
    const [filledTabs, setFilledTabs] = useState([]);
    const pages = jsonData[0].pages;
    const [isTabChanged, setIsTabChanged] = useState(Array(pages.length).fill(false));
    const [dropdownOptions, setDropdownOptions] = useState({
        Course: [],
        State: [],
        EmployeeCategory: [],
    });


    const username = sessionStorage.getItem('name');
    const fullname = sessionStorage.getItem('fullname');
    const navigate = useNavigate();
    useEffect(() => {
        const storedApplicantId = sessionStorage.getItem('application_id') || applicationId;
        if (storedApplicantId) {
            setApplicantId(storedApplicantId);
        }
    }, []);

    const fetchDropdownData = useCallback(async () => {
        const courseOptions = await GETDROPDOWNDATA({ type: 'Course' });
        const stateOptions = await GETDROPDOWNDATA({ type: 'State' });
        const employeeCategoryOptions = await GETDROPDOWNDATA({ type: 'Employee Category' });
        setDropdownOptions({
            Course: courseOptions.data.data,
            State: stateOptions.data.data,
            EmployeeCategory: employeeCategoryOptions.data.data,
        });
    }, [dropdownOptions, setDropdownOptions]);

    useEffect(() => {
        fetchDropdownData();
    }, [setDropdownOptions]);



    useEffect(() => {
        console.log("activeTab", activeTab);
        console.log("showTable", showTable);
        console.log("parentTab", parentTab);
        console.log("filledTabs", filledTabs);
        console.log("isTabChanged", isTabChanged);
        console.log("dropdownOptions", dropdownOptions['Course']);
    }, [activeTab, parentTab, filledTabs, dropdownOptions])


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        const currentField = pages[activeTab].formData.find(field => field.name === name);
        if (currentField.type === 'text' && currentField.subtype) {
            let error = '';
            let pattern = currentField.pattern;

            if (typeof pattern === 'string') {
                try {
                    pattern = new RegExp(pattern);
                } catch (e) {
                    console.error("Invalid regex pattern:", e);
                    pattern = null;
                }
            }

            if (pattern && !pattern.test(value)) {
                if (currentField.subtype === 'email') {
                    error = 'Please enter a valid email address.';
                } else if (currentField.subtype === 'tel') {
                    error = 'Please enter a valid phone number.';
                }
            }
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: error,
            }));
        }


        setIsTabChanged((prev) => {
            const newIsTabChanged = [...prev];
            newIsTabChanged[activeTab] = true;
            return newIsTabChanged;
        });
    };


    const handleTabChange = (index) => {

        if (filledTabs.includes(index)) {
            if (isTabChanged[activeTab]) {
                if (window.confirm("You have unsaved changes. Do you want to save them?")) {
                    handleSaveAndContinue();
                    setActiveTab(index);
                } else {
                    setActiveTab(index);
                    setIsTabChanged(Array(pages.length).fill(false));
                }
            } else {
                setActiveTab(index);
            }
        } else {
            alert("Please click on save and continue to proceed.");
        }
    };

    useEffect(() => {
        const lastPageIndex = pages.findIndex(page => page.formData.some(field => field.type === 'hidden' && field.value === last_page));
        if (application_status === 'Documents Uploaded') {
            setParentTab(-3);
            setActiveTab(lastPageIndex);
            const filledTabs = Array.from({ length: lastPageIndex + 1 }, (_, index) => index);
            setFilledTabs(filledTabs);
        } else if (application_status === 'Applicant Details Submitted') {
            setParentTab(-1);
            setActiveTab(lastPageIndex);
            const filledTabs = Array.from({ length: lastPageIndex + 1 }, (_, index) => index);
            setFilledTabs(filledTabs);
        }else if (application_status === 'Course Details Submitted') {
            setParentTab(-2);
            setActiveTab(lastPageIndex);
            const filledTabs = Array.from({ length: lastPageIndex + 1 }, (_, index) => index);
            setFilledTabs(filledTabs);
        } else {
            if (lastPageIndex !== -1) {
                setParentTab(0);
                setActiveTab(lastPageIndex);
                const filledTabs = Array.from({ length: lastPageIndex + 1 }, (_, index) => index);
                setFilledTabs(filledTabs);
            } else {
                setParentTab(0);
                setActiveTab(0);
            }
        }
    }, [application_status, last_page, pages]);

    const handlePreviousTab = () => {
        if (activeTab > 0) {
            handleTabChange(activeTab - 1);
        }
    };

    const handleSaveAndContinue = async () => {

        const currentPage = pages[activeTab];
        const currentFormData = currentPage.formData.reduce((acc, field) => {
            if (field.type !== 'hidden') {
                if (field.type !== 'hidden') {
                    const key = field.label.replace(/\s+/g, '_');
                    acc[key] = formData[field.name] || '';
                }
            }
            return acc;
        }, {});

        const payload = {
            "applicant_id": applicantId,
            "logged_in_user": fullname,
            "affiliation_code": affiliation_code,
            "university_code": UNIVERSITY_CODE,
            ...currentFormData,
            "page": currentPage.formData.find(field => field.type === 'hidden')?.value || ''
        }

        try {
            const res = await SAVEINSTITUTEDATA(payload);
            if (res.data.status == 'failure') {
                alert(res.data.message);
                return false;
            }
            if (res.data.applicant_id) {
                setApplicantId(res.data.applicant_id);
            }
            setIsTabChanged((prev) => {
                const newIsTabChanged = [...prev];
                newIsTabChanged[activeTab] = false;
                return newIsTabChanged;
            });
            setShowTable(false);
            return true;
        } catch (error) {
            console.error("Error saving data:", error);
            return false;
        }
    };

    const handleSaveAndNext = async () => {

        const currentPage = pages[activeTab];
        const allowSaveField = currentPage.formData.find(field =>
            field.type === 'hidden' && field.name === 'allowsave'
        );

        const isSaveAllowed = allowSaveField && allowSaveField.value === 'true';

        if (!isSaveAllowed) {
            if (showTable && tableData.length == 0) {
                alert("Please add the data before saving");
                return;
            }
            setActiveTab((activeTab + 1) % pages.length);
            setFilledTabs([...filledTabs, activeTab]);
            return;
        }


        const allFieldsFilled = currentPage.formData.every(field => {
            if (field.required) {
                return formData[field.name] && formData[field.name].trim() !== '';
            }
            return true;
        });
        if (!allFieldsFilled) {
            alert("Please fill out all required fields before proceeding to the next tab.");
            return;
        }
        if (Object.values(errors).some(error => error)) {
            alert("Please fix the errors before saving.");
            return;
        }
        const saveSuccess = await handleSaveAndContinue();
        if (!saveSuccess) {
            return;
        }
        setFilledTabs([...filledTabs, activeTab]);
        if (activeTab === pages.length - 1) {
            sessionStorage.setItem('application_status', 'Applicant Details Submitted')
            setParentTab(-1);
        } else {
            setActiveTab((activeTab + 1) % pages.length);
        }
    };

    const handleAddButtonClick = async () => {
        setShowTable(true);
        const currentPageData = pages[activeTab].formData.reduce((acc, field) => {
            if (field.type !== 'hidden') {
                acc[field.label] = formData[field.name] || '';
            }
            return acc;
        }, {});
        const allFieldsFilled = pages[activeTab].formData.every(field => {
            if (field.required) {
                return formData[field.name] && formData[field.name].trim() !== '';
            }
            return true;
        });
        if (!allFieldsFilled) {
            alert("Please fill out all required fields before adding.");
            return;
        }
        const payload = {
            "applicant_id": applicantId,
            "pageData": currentPageData,
            "page": pages[activeTab].formData.find(field => field.type === 'hidden')?.value || '',
            "logged_in_user": username
        }
        const res = await SAVEINSTITUTEDATA(payload);
        if (res.data.status == 'failure') {
            alert(res.data.message);
            return;
        }
        setTableData(prevTableData => [...prevTableData, currentPageData]);
        setFormData({});
    };

    const handleParentTab = async () => {
        setParentTab(-3);
    }

    const handleParentTabForCourses = async () => {
        setParentTab(-2);
    }

    const handleDelete = async (index) => {
        const currentPage = pages[activeTab];
        const entryToDelete = tableData[index];

        const payload = {
            row: {
                ...entryToDelete,
                "applicant_id": applicantId
            },
            page: currentPage.formData.find(field => field.type === 'hidden')?.value || ''
        }
        const resp = await DELETETABLEDATA(payload);
        if(resp){
            alert(resp.data.message);
        }
        const newTableData = [...tableData];
        newTableData.splice(index, 1);
        setTableData(newTableData);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (applicantId) {
                const currentPage = pages[activeTab];
                const payload = {
                    "applicant_id": applicantId,
                    "page": currentPage.formData.find(field => field.type === 'hidden')?.value || ''
                };
                try {
                    const res = await GETINSTITUTEDATA(payload);
                    const newFormData = {};
                    const originalLabels = {};
                    if (res.data) {
                        setShowTable(res.data.table_data);
                        setTableData(res.data.message);

                    }
                    currentPage.formData.forEach(field => {
                        if (field.type !== 'hidden') {
                            originalLabels[field.label.replace(/\s+/g, '_')] = field.name;
                        }
                    });
                    Object.keys(res.data.message).forEach(key => {
                        const originalFieldName = originalLabels[key];
                        if (originalFieldName) {
                            newFormData[originalFieldName] = res.data.message[key];
                        }
                    });
                    setFormData(newFormData);
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };

        fetchData();
    }, [applicantId, activeTab, showTable]);

    return (
        <div className="container">
            <div className="ProfleDtls">
                <div className="row">
                    <div className="col-lg-12">
                        {applicationId && (
                            <button onClick={() => navigate(-1)} className="back-arrow-btn">
                                <span><img src={back}/></span>
                            </button>
                        )}
                        <div className="ProfleWrap">
                            <hr className="hr" />
                            <ul className="nav nav-pills mb-5 justify-content-center" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link Proflealink ${parentTab === 0 ? 'active' : ''}`} id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={() => setParentTab(0)}>Profile Details</button>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link Proflealink ${parentTab === -1 ? 'active' : ''}`} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="true" onClick={() => { setShowTable(false); setParentTab(-1); }}
                                        disabled={(application_status == 'Registered' || application_status == null)} style={{ opacity: (application_status == 'Registered' || application_status == null) ? 0.5 : 1, cursor: application_status == 'Registered' ? 'not-allowed' : 'pointer' }}
                                    >Documents Upload</button>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link Proflealink ${parentTab === -3 ? 'active' : ''}`} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="true" onClick={() => { setShowTable(false); setParentTab(-3); }}
                                        disabled={(application_status == 'Registered' || application_status == null|| application_status == 'Applicant Details Submitted')} style={{ opacity: (application_status == 'Registered' || application_status == null|| application_status == 'Applicant Details Submitted') ? 0.5 : 1, cursor: (application_status == 'Registered'|| application_status == 'Applicant Details Submitted'|| application_status == 'null' )? 'not-allowed' : 'pointer' }}
                                    >Courses</button>
                                </li>

                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link Proflealink last-cls ${parentTab === -2 ? 'active' : ''}`} id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="true" onClick={() => { setShowTable(false); setParentTab(-2) }}
                                        disabled={(application_status == 'Registered' || application_status == null || application_status == 'Applicant Details Submitted' || application_status == 'Documents Uploaded')} style={{ opacity: (application_status == 'Registered' || application_status == null || application_status == 'Applicant Details Submitted'|| application_status == 'Documents Uploaded') ? 0.5 : 1, cursor: (application_status == 'Registered' || application_status == null || application_status == 'Applicant Details Submitted'|| application_status == 'Documents Uploaded') ? 'not-allowed' : 'pointer' }}
                                    >Payment</button>
                                </li>
                            </ul>
                            <div className="tab-content p-5 TabProgile" id="pills-tabContent">
                                {parentTab == 0 && (
                                    <div className="tab-pane fade show Profleactive active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <nav className="text-center">
                                            <div className="nav nav-tabs ProfleBrd d-inline-flex mb-5" id="nav-tab" role="tablist">
                                                {pages.map((page, index) => {
                                                    const hiddenField = page.formData.find(field => field.type === 'hidden' && field.name === 'tabname');
                                                    return (
                                                        <li className="nav-item" role="presentation" key={page.id}>
                                                            <button
                                                                className={`nav-link tab-nav ${activeTab === index ? 'active' : ''}`}
                                                                onClick={() => handleTabChange(index)}
                                                                type="button"
                                                                role="tab"
                                                                aria-controls={`pills-${page.id}`}
                                                                aria-selected={activeTab === index}
                                                            >
                                                                {hiddenField ? hiddenField.value : page.name}
                                                            </button>
                                                        </li>
                                                    );
                                                })}

                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent">
                                            {pages.map((page, index) => (
                                                <div
                                                    key={page.id}
                                                    className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}
                                                    id={`pills-${page.id}`}
                                                    role="tabpanel"
                                                    aria-labelledby={`pills-${page.id}-tab`}

                                                >
                                                    <div className="row ml-2">
                                                        {page.formData.map((field, fieldIndex) => {
                                                            if (field.type === 'hidden') return null;
                                                            const wrapperClassName = (field.className?.includes('form-control') ? 'col-12 col-lg-6 mb-2' : `form-field ${field.type} ${field.className} mb-2`);

                                                            return (
                                                                <div key={fieldIndex} className={wrapperClassName}>

                                                                    {field.type === 'button' && (
                                                                        <button className='btn addBtn' onClick={handleAddButtonClick}>{field.label}</button>
                                                                    )}
                                                                    {field.type === 'header' && (
                                                                        <h1>{field.label}</h1>
                                                                    )}
                                                                    {field.type === 'paragraph' && (
                                                                        <p>{field.label}</p>
                                                                    )}
                                                                    {field.type === 'text' && (
                                                                        <div className="form-group ">
                                                                            <label htmlFor={field.name} className="formlabel">{field.label}{field.required && <span className="text-danger">*</span>}</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control fromFilespop"                                                                                 // className="form-control fromFiles"
                                                                                name={field.name}
                                                                                id={field.name}
                                                                                aria-describedby="helpId"
                                                                                placeholder={field.placeholder}
                                                                                onChange={handleInputChange}
                                                                                required={field.required}
                                                                                value={formData[field.name] || ''}
                                                                            />
                                                                            {errors[field.name] && <div className="invalid-feedback" style={{ display: 'block' }}>{errors[field.name]}</div>}

                                                                        </div>
                                                                    )}
                                                                    {field.type === 'textarea' && (
                                                                        <div className="form-group">
                                                                            <label htmlFor={field.name} className="formlabel">{field.label}{field.required && <span className="text-danger">*</span>}</label>
                                                                            <textarea
                                                                                name={field.name}
                                                                                id={field.name}
                                                                                className="form-control fromFilespop"
                                                                                placeholder={field.placeholder}
                                                                                onChange={handleInputChange}
                                                                                required={field.required}
                                                                                value={formData[field.name] || ''}
                                                                            ></textarea>
                                                                        </div>
                                                                    )}
                                                                    {field.type === 'select' && (
                                                                        <div className="form-group">
                                                                            <label htmlFor={field.name} className="formlabel">{field.label}{field.required && <span className="text-danger">*</span>}</label>
                                                                            <select name={field.name} id={field.name} className="form-control fromFiles" onChange={handleInputChange} required={field.required} value={formData[field.name] || ''}>
                                                                                <option value="" disabled>{field.placeholder || 'Select an option'}</option>
                                                                                {field.values.map((option, optIndex) => (
                                                                                    <option key={optIndex} value={option.value} selected={option.selected}>
                                                                                        {option.label}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    )}
                                                                    {field.type === 'customSelect' && (
                                                                        <div className="form-group">
                                                                            <label htmlFor={field.name} className="formlabel">{field.label}{field.required && <span className="text-danger">*</span>}</label>
                                                                            <select
                                                                                id={field.name}
                                                                                name={field.name}
                                                                                className="form-control fromFilespop"
                                                                                onChange={handleInputChange}
                                                                                value={formData[field.name] || ''}
                                                                            >
                                                                                <option value="" disabled>Select an option</option>
                                                                                {dropdownOptions[field.subtype] && dropdownOptions[field.subtype].map((option, index) => (
                                                                                    <option key={index} value={option.name}>
                                                                                        {option.name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    )}
                                                                    {field.type === 'date' && (
                                                                        <div className="form-group">
                                                                            <label htmlFor={field.name} className="formlabel">
                                                                                {field.label} {field.required && <span className="text-danger">*</span>}
                                                                            </label>
                                                                            <input
                                                                                type="date"
                                                                                className="form-control fromFilespop"
                                                                                name={field.name}
                                                                                id={field.name}
                                                                                onChange={handleInputChange}
                                                                                required={field.required}
                                                                                value={formData[field.name] || ''}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                    {field.type === 'radio-group' && (
                                                                        <div className="form-group">
                                                                            <label className="formlabel">{field.label}{field.required && <span className="text-danger">*</span>}</label>
                                                                            <div className='d-flex'>
                                                                                {field.values.map((option, optIndex) => (
                                                                                    <div key={optIndex} className="form-check mr-5">
                                                                                        <input
                                                                                            type="radio"
                                                                                            className="form-check-input"
                                                                                            name={field.name}
                                                                                            id={option.value}
                                                                                            value={option.value}
                                                                                            checked={formData[field.name] === option.value}
                                                                                            onChange={handleInputChange}
                                                                                            required={field.required}
                                                                                        />
                                                                                        <label className="form-check-label" htmlFor={option.value}>
                                                                                            {option.label}
                                                                                        </label>
                                                                                    </div>
                                                                                ))}
                                                                            </div>

                                                                        </div>
                                                                    )}
                                                                    {field.type === 'checkbox-group' && (
                                                                        <div className="form-group">
                                                                            <label className="formlabel">{field.label}{field.required && <span className="text-danger">*</span>}</label>
                                                                            <div className={field.inline ? 'd-inline-flex' : ''}>
                                                                                {field.values.map((option, optIndex) => (
                                                                                    <div key={optIndex} className="form-check">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            name={field.name}
                                                                                            value={option.value}
                                                                                            checked={formData[field.name]?.includes(option.value)}
                                                                                            onChange={handleInputChange}
                                                                                            disabled={field.access}
                                                                                        />
                                                                                        <label className="form-check-label" htmlFor={option.value}>
                                                                                            {option.label}
                                                                                        </label>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    {field.type === 'number' && (
                                                                        <div className="form-group">
                                                                            <label htmlFor={field.name} className="formlabel">{field.label}{field.required && <span className="text-danger">*</span>}</label>
                                                                            <input
                                                                                type="number"
                                                                                className="form-control fromFiles"
                                                                                name={field.name}
                                                                                id={field.name}
                                                                                onChange={handleInputChange}
                                                                                value={formData[field.name] || ''}
                                                                                required={field.required}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        })}

                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                                        <div className='col-xs-12 col-lg-12'>
                                                        {showTable && parentTab === 0 && (
                                <div className="mt-2 mb-4">
                                    <h4>Saved Data</h4>
                                    <table className="university-table">
                                        <thead>
                                            <tr>
                                                {pages[activeTab].formData
                                                    .filter((field) => field.type !== 'hidden') // Exclude 'hidden' fields from header
                                                    .map((field) => (
                                                        field.type !== 'button' ? (
                                                            <th key={field.name}>{field.label}</th>
                                                        ) : (
                                                            <th key={field.name}></th>
                                                        )
                                                    ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((data, index) => (
                                                <tr key={index}>
                                                    {pages[activeTab].formData.map((field) => (
                                                        field.type !== 'hidden' ? (
                                                            field.type !== 'button' ? (
                                                                <td key={field.name}>{data[field.label]}</td>
                                                            ) : (
                                                                <td key={field.name}>
                                                                    <button
                                                                        className="btn btn-danger"
                                                                        onClick={() => handleDelete(index)}
                                                                    >
                                                                        <img src={deleteIcon} alt="Delete" />
                                                                    </button>
                                                                </td>
                                                            )
                                                        ) : null
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>
                            )}
                                                            </div>

                                        <div className="col-xs-12 col-lg-12">
                                            <div className="text-center mt-4 mb-3">

                                                <button
                                                    type="button"
                                                    className="btn previousbtn"
                                                    onClick={handlePreviousTab}
                                                    disabled={activeTab === 0}
                                                >
                                                    Previous
                                                </button>
                                                <button type="submit" className="btn saveBtn" id="pills-profile" onClick={handleSaveAndNext}>
                                                    Save and Continue
                                                </button>
                                            </div>
                                        </div>
                                    </div>)}


                                {parentTab === -1 && (
                                    <div className={`tab-pane fade show ${parentTab === -1 ? 'active' : ''}`} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" >
                                        <DocumentUpload applicationId={applicantId} onSaveAndContinue={() => handleParentTab()}/>
                                    </div>
                                )}
                                {parentTab == -2 && (
                                    <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <Payment application_id={applicantId}/>
                                    </div>
                                )}
                                {parentTab == -3 && (
                                    <div className="tab-pane fade show active" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <CourseTable applicationId={applicantId} onSaveAndContinue={()=> handleParentTabForCourses()}/>
                                    </div>
                                )}
                                

                            </div>
                           

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TabbedFormRenderer;

