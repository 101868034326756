import {HttpClient} from '../../http-service/api';
import {API_LIST} from '../../http-service/list';


export const GETUNIVERSITIES = (payload) => {
    return HttpClient.get(API_LIST.GETUNIVERSITIES,payload);
}

export const ADDUNIVERSITY = (payload) => {
    return HttpClient.post(API_LIST.ADDUNIVERSITY,payload);
}
export const UPDATEUNIVERSITY = (payload) => {
    return HttpClient.post(API_LIST.UPDATEUNIVERSITY,payload);
}
export const DELETEUNIVERSITY = (payload) => {
   return HttpClient.post(API_LIST.DELETEUNIVERSITY,payload);
}
export const GETAFFILIATIONS = (payload) => {
    return HttpClient.get(API_LIST.GETAFFILIATIONS,payload);
}

export  const ADDAFFILIATIONS = (payload) => {
    return HttpClient.post(API_LIST.ADDAFFILIATIONS,payload);
}

export const UPDATEAFFILIATION = (payload) => {
    return HttpClient.post(API_LIST.UPDATEAFFILIATION,payload);
}

export const DELETEAFFILIATION= (payload) => {
   return HttpClient.post(API_LIST.DELETEAFFILIATION,payload);
}

export const APPLICATIONLIST= (payload) => {
    return HttpClient.post(API_LIST.APPLICATIONLIST,payload);
}

export const GETTYPES = () => {
    return HttpClient.post(API_LIST.GETTYPES);
}
export const GETDROPDOWNDATA = (payload) => {
    return HttpClient.post(API_LIST.GETDROPDOWNDATA,payload);
}
export const DELETEDROPDOWNDATA = (payload) => {
    return HttpClient.post(API_LIST.DELETEDROPDOWNDATA,payload);
}
export const ADDDROPDOWNDATA = (payload) => {
    return HttpClient.post(API_LIST.ADDDROPDOWNDATA,payload);
}
export const UPDATEDROPDOWNDATA = (payload) => {
    return HttpClient.post(API_LIST.UPDATEDROPDOWNDATA,payload);
}

export const GETDEPENDENCIES = (payload) => {
    return HttpClient.post(API_LIST.GETDEPENDENCIES,payload);
}

export const ADDDEPENDENCY = (payload) => {
    return HttpClient.post(API_LIST.ADDDEPENDENCY,payload);
}

export const GETDEPENDENCYVALUES = (payload) => {
    return HttpClient.post(API_LIST.GETDEPENDENCYVALUES,payload);
}
export const SAVEDEPENDENTDROPDOWNVALUES = (payload) => {
    return HttpClient.post(API_LIST.SAVEDEPENDENTDROPDOWNVALUES,payload);
}
export const DLTDEPENDENTDATA = (payload) => {
    return HttpClient.post(API_LIST.DLTDEPENDENTDATA,payload);
}