import React, { useState, useEffect } from 'react';
import './Sidebar.css'; // Import the CSS for styling
import './Admission.css';
// import './font-awesome.min.css'
import usericon from '../img/usericon.png';
import 'bootstrap/dist/css/bootstrap.css';
import { Link } from 'react-router-dom';
import { useSidebar } from './SidebarContext';
import { FaBars, FaTimes, FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { universityData } from './LoginComponents/LoginServices';
import { UNIVERSITY_CODE } from '../config';
import { useLocation } from 'react-router-dom';


const Sidebar = () => {
  //   const [isOpen, setIsOpen] = useState(false); // Sidebar starts closed
  const [activeIndex, setActiveIndex] = useState(0); // Track active menu item
  const { isOpen, toggleSidebar } = useSidebar();
  const navigate = useNavigate();
  const role =sessionStorage.getItem("role");
  const [universityLogo, setUniversityLogo] = useState('');
  const location = useLocation();

  //   const toggleSidebar = () => {
  //     setIsOpen(!isOpen);
  //   };
  useEffect(() => {
    const savedIndex = sessionStorage.getItem('activeIndex');
    
    if (savedIndex !== null) {
      setActiveIndex(Number(savedIndex));
    } else {
      setActiveIndex(0); // Set Dashboard as the default active index
      sessionStorage.setItem('activeIndex', 0); // Also store 0 in sessionStorage for future reference
    }

    const fetchUniversityDetails = async () => {
      let payload = {
        university_code: UNIVERSITY_CODE,
      };
      try {
        const response = await universityData(payload);
        if(response.data.status === 'failure') {
          alert(response.data.message);
        } else {
          setUniversityLogo(response.data.message[0].logo_url);
        }
      } catch (error) {
        console.error("Error fetching university details:", error);
      }
    };
  
    fetchUniversityDetails();
  }, []);

  useEffect(() => {
    // Update activeIndex based on the current path
    const currentPath = location.pathname;
    const index = menuItems.findIndex(
      (item) => `/${item.toLowerCase().replace(/\s+/g, '-')}` === currentPath
    );
    if (index !== -1) {
      setActiveIndex(index);
      sessionStorage.setItem('activeIndex', index);
    }
  }, [location]);
  
  
  const menuItems = (() => {
    switch (role) {
      case "SUPADM":
        return ['Dashboard', 'University Setup', 'Affiliation Setup', 'Manage Forms', 'Applications List', 'Generic Setup'];
      case "ADM":
        return ['Dashboard', 'Users List', 'Applications List', 'Reports', 'Fee Structure', 'Courses'];
      default:
        return []; // Return an empty array for unknown roles
    }
  })();
  

  const handleMenuItemClick = (index) => {
    setActiveIndex(index);
    sessionStorage.setItem('activeIndex', index);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/university-login');
  }

  const handlePasswordChange = () => {
    sessionStorage.clear();
    navigate('/change-password');
  }

  return (
    <>
      {/* <div className="main-container">  */}
      <div className={`content ${isOpen ? 'active-cont' : ''}`}>

        <nav className={`sidebar ${isOpen ? 'active-nav' : ''}`}>
          <button className="sidebar-toggle" onClick={toggleSidebar}>
          {isOpen ? <FaTimes /> : <FaBars />}
          </button>
          <div className='scrollbar'>

            <ul>
              <div className='text-center'>
                <Link to="/" className="h3 text-white my-0 alogo p-0">
                  <img src={universityLogo ? universityLogo : "images/alogo.png"} style={universityLogo ? { width: '40%' } : {}} alt="Logo" />
                </Link>
                <div className="hrline"></div>
              </div>
              {
              // ['Dashboard','University Setup', 'Affiliation Setup','Affiliation List', 'Users List', 'Applications List', 'Workflow Management', 'Reports']
              menuItems.map((item, index) => (
                <li key={index}>
                  <Link
                    to={`/${item.toLowerCase().replace(/\s+/g, '-')}`}
                    className={activeIndex === index ? 'active' : ''}
                    onClick={() => handleMenuItemClick(index)}
                  >
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </nav>

        <div className='row'>
          <div className='col-12 col-lg-12'>
            <div className='bg-white radius6 padAll'>
              <div className='row'>
                <div className='col-12 col-lg-8'>
                  <div className='BrTitlr'>
                    <h1>
                      Welcome To University
                    </h1>
                  </div>
                </div>
                <div className='col-12 col-lg-4'>
                  <form>
                    
                    <div className="btn-group float-end">
                      <a data-bs-toggle="dropdown" className='userIcon' aria-expanded="true">
                        <img className='cursor' src={usericon} />
                      </a>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li><button className="dropdown-item" type="button" onClick={handlePasswordChange}>Change Password</button></li>
                        <li><button className="dropdown-item" type="button" onClick={handleLogout}>Logout</button></li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Dashboard/> */}
      </div>
      {/* </div> */}
    </>
  );
};

export default Sidebar;
