import React, { useState, useEffect } from 'react';
import {GET_DEPENDENT_DATA, COURSEPAYMENTDATA, SAVEINSTITUTECOURSES, GETINSTITUTECOURSES} from './HomeServices';
import {UNIVERSITY_CODE} from '../../config';

function CourseTable({applicationId, onSaveAndContinue}) {

  const [courseData, setCourseData] = useState({});
  const [rows, setRows] = useState([
    { courseType: '', course: '', branch: '', noOfSeats: '', amount: '' }
  ]);
  const [amountData, setAmountData] = useState([]);
  

  const [selectedCombinations, setSelectedCombinations] = useState([]);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const response = await GET_DEPENDENT_DATA({university_code: UNIVERSITY_CODE});
        if (response && response.data && response.data.status === "success") {
          setCourseData(response.data.message);
        } else {
          console.error("Failed to fetch course data", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    const fetchAmountData = async () => {
      try {
        const response = await COURSEPAYMENTDATA({university_code: UNIVERSITY_CODE});
        if (response && response.data && response.data.status === "success") {
          setAmountData(response.data.data);
        } else {
          alert(response.data.data);
          console.error("Failed to fetch course data", response.data);
        }
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };
    const fetchCoursesValues = async () => {
      try {
        const response = await GETINSTITUTECOURSES({application_id: applicationId});
        if (response && response.data && response.data.status === "success") {
          setRows(response.data.message);
          const fetchedRows = response.data.message;    
          const combinations = fetchedRows.map(row => ({
            courseType: row.courseType,
            course: row.course,
            branch: row.branch
          }));
    
          setSelectedCombinations(prevCombinations => [
            ...prevCombinations,
            ...combinations
          ]);
        } else {
          console.error("Failed to fetch course data", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    fetchAmountData();
    fetchCourseData();
    fetchCoursesValues();
  }, [applicationId]);

  const isDuplicateCombination = (courseType, course, branch) => {
    return selectedCombinations.some(
      (row) => row.courseType === courseType && row.course === course && row.branch === branch
    );
  };

  const handleInputChange = (e, index, field) => {
    const newRows = [...rows];
    newRows[index][field] = e.target.value;

    if (field === 'courseType') {
      newRows[index].course = '';
      newRows[index].branch = '';
    }

    if (
      newRows[index].courseType &&
      newRows[index].course &&
      newRows[index].branch &&
      isDuplicateCombination(newRows[index].courseType, newRows[index].course, newRows[index].branch)
    ) {
      alert("This combination of Course Type, Course, and Branch already exists.");
      return;
    }

    setSelectedCombinations((prevCombinations) => [
      ...prevCombinations,
      {
        courseType: newRows[index].courseType,
        course: newRows[index].course,
        branch: newRows[index].branch,
      },
    ]);

    setRows(newRows);
  };

  const handleSeatsChange = (e, index) => {
    const selectedSeats = e.target.value;
    const newRows = [...rows];
    newRows[index].noOfSeats = selectedSeats;

    const courseDetails = amountData.find(
      (course) => course.course_type === newRows[index].courseType && course.course === newRows[index].course && course.branch === newRows[index].branch
    );

    if (courseDetails) {
      const selectedCourseDetail = courseDetails.course_details.find(
        (detail) => detail.seats == selectedSeats
      );
      if (selectedCourseDetail) {
        newRows[index].amount = selectedCourseDetail.amount;
      }
    }
    setRows(newRows);
  };

  const addRow = () => {
    setRows([
      ...rows,
      { courseType: '', course: '', branch: '', noOfSeats: '', amount: '' }
    ]);
  };

  const removeRow = (index) => {
    const newRows = rows.filter((row, i) => i !== index);
    setRows(newRows);
    updateSelectedCombinations(newRows);
  };

  const updateSelectedCombinations = (updatedRows) => {
    const combinations = updatedRows.map(row => ({
      courseType: row.courseType,
      course: row.course,
      branch: row.branch
    }));
    setSelectedCombinations(combinations);
  };

  const saveAndContinue = async () => {
    const payload = {
      application_id: applicationId,
      data: rows
    }

    try {
      const response = await SAVEINSTITUTECOURSES(payload);
      if (response && response.data && response.data.status === "success") {
        alert('Data saved successfully!');
        onSaveAndContinue();
        console.log('Saved Data:', response.data);
      } else {
        alert(response.data.message);
        console.error('Error saving data:', response.data);
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const isCombinationSelected = (courseType, course, branch) => {
    return selectedCombinations.some(
      (combination) => combination.courseType === courseType && combination.course === course && combination.branch === branch
    );
  };


  useEffect(()=> {
    console.log("rows",rows)
  },[rows]);
  return (
    <div>
      <h2>Course Information</h2>
      <table width={('100%')} className='formtable'>
        <thead>
          <tr>
            <th>Course Type</th>
            <th>Course</th>
            <th>Branch</th>
            <th>No. of Seats</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                <select className='droparrow-select-course'
                  value={row.courseType}
                  onChange={(e) => handleInputChange(e, index, 'courseType')}
                >
                  <option value="">Select</option>
                  {Object.keys(courseData).map((type, i) => (
                    <option key={i} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select className='droparrow-select-course'
                  value={row.course}
                  onChange={(e) => handleInputChange(e, index, 'course')}
                  disabled={!row.courseType}
                >
                  <option value="">Select</option>
                  {row.courseType && courseData[row.courseType] &&
                    courseData[row.courseType].courses.map((course, i) => (
                      <option key={i} value={course}>
                        {course}
                      </option>
                    ))}
                </select>
              </td>
              <td>
                <select className='droparrow-select-course'
                  value={row.branch}
                  onChange={(e) => handleInputChange(e, index, 'branch')}
                  disabled={!row.course}
                >
                  <option value="">Select</option>
                  {row.course && courseData[row.courseType] &&
                    courseData[row.courseType].branches[row.course] &&
                    courseData[row.courseType].branches[row.course].map(
                      (branch, i) => (
                        <option key={i} value={branch} disabled={isCombinationSelected(row.courseType, row.course, branch)}>
                          {branch}
                        </option>
                      )
                    )}
                </select>
              </td>
              <td>
                <select className='droparrow-select-course'
                  value={row.noOfSeats}
                  onChange={(e) => handleSeatsChange(e, index)}
                  disabled={!row.branch}
                >
                  <option value="">Select</option>
                  {row.branch &&
                    amountData
                      .find((type) => type.course_type === row.courseType && type.course === row.course && type.branch === row.branch)
                      ?.course_details.map((courseDetail, i) => (
                        <option key={i} value={courseDetail.seats}>
                          {courseDetail.seats}
                        </option>
                      ))}
                </select>
              </td>
              <td>
                <input className='form-control fromFilespop'
                  type="text"
                  value={row.amount}
                  readOnly
                />
              </td>
              <td>
                <button className='btn saveHBtn' onClick={() => removeRow(index)}>Remove</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='mt-3 text-right'>
        <button className='btn previousbtn' onClick={addRow}>Add Row</button>
        <button className='btn saveBtn' onClick={saveAndContinue}>
          Save and Continue
        </button>
      </div>
    </div>
  );
}

export default CourseTable;
