import React from 'react';
import Brand from '../../../img/alogo.png';
import { useNavigate } from 'react-router-dom';
import ApplyIcon from '../../../img/applyIcon.png';
import LginIcon from '../../../img/loginIcon.png'; 

const Header = ({universityDetails}) => {
    const navigate = useNavigate();
    const handleLogout = () => {
        sessionStorage.clear();
        navigate('/applicant-login');
      }
    const handleHome = () => {
        navigate('/home');
    }
    return (
        <div className='py-2 container-fluid'>
            <nav className='navbar navbar-expand-lg navbar-light bg-white Bx-shadow pl-3'>
                <a className='navbar-brand me-auto brand BrandLogo' href='#'><img src={universityDetails ? universityDetails.logo_url : Brand}/> </a>
                <form className="d-flex justify-content-end">
                    <button className="btn loginBtn Hicons" type="submit" onClick={handleHome}><span><img src={LginIcon} alt = "login icon"/> </span> Home</button>
                    <button className="btn siginBtn Hicons" type="submit" onClick={handleLogout}><span><img src={ApplyIcon} alt = "register icon"/> </span> Log out</button>
                </form>
            </nav>
        </div>
    );
};

export default Header;

