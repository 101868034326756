import React, { useState, useEffect } from 'react';
import { GETTYPES, ADDDEPENDENCY } from './UniversityServices';
import CrudOperations from './CrudOperations';
import './GenericSetup.css';

const GenericSetup = () => {
  const [selectedType, setSelectedType] = useState('');
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [newOption, setNewOption] = useState(''); // State for the input field when adding a new option
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [availableDependencies, setAvailableDependencies] = useState([]);
  const [selectedDependencies, setSelectedDependencies] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown collapse/expand

  // Fetch dropdown options from the API
  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await GETTYPES();
        console.log("types:" + response.data)
        if (response.data.status === 'failure') {
          alert(response.data.message);
        } else {
          setDropdownOptions(response.data);
          const dependencies = response.data.map(item => ({ type: item.type }));
          setAvailableDependencies(dependencies);
          console.log("====", dependencies);
        }
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };

    fetchDropdownOptions();
  }, []);

  const handleTypeChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === 'add') {
      setShowModal(true); // Open modal if "Add New" is selected
    } else {
      setSelectedType(selectedValue);
    }
  };

  const handleInputChange = (e) => {
    setNewOption(e.target.value);
  };

  const handleAddOption = async (e) => {
    e.preventDefault();
    if (newOption.trim() === '') {
      alert('Enter New Type.');
      return;
    }
  
    try {
      const response = await ADDDEPENDENCY({
        type: newOption,
        dependencies: selectedDependencies.map((dep) => dep.type)
      });
      
      if (response.data.status === 'failure') {
        alert(response.data.message);
      } else {
        // Update the state with the newly added type and dependencies
        const newOptionObj = { type: newOption, dependencies: selectedDependencies };
        setDropdownOptions([...dropdownOptions, newOptionObj]);
        setSelectedType(newOption); // Select the new option
        setNewOption('');
        setSelectedDependencies([]);
        setShowModal(false); // Close modal after adding
      }
    } catch (error) {
      console.error('Error adding new option:', error);
      alert('Failed to add new type. Please try again.');
    }
  };
  

  const handleDependencySelect = (dependency) => {
    setSelectedDependencies((prev) =>
      prev.includes(dependency)
        ? prev.filter((dep) => dep !== dependency)
        : [...prev, dependency]
    );
  };

  return (
    <div className="BxAllSide px-0 py-0">
      <div className="BxApple p-6 AdinTitle">
        <h1>Generic Setup</h1>

        <div className="align-items-center">
          <div className="row">
            <div className="col-12 col-lg-3">
              <select
                className="form-select all-dropselect mt-5"
                onChange={handleTypeChange}
                value={selectedType}
              >
                <option value="">Select Type</option>
                {dropdownOptions.map((option, index) => (
                  <option key={option.type || index} value={option.type}>
                    {option.type.charAt(0).toUpperCase() + option.type.slice(1)}
                  </option>
                ))}
                <option value="add">Add New</option>
              </select>
            </div>
          </div>
        </div>

        {selectedType && selectedType !== 'add' && (
          <CrudOperations selectedType={selectedType} />
        )}
      </div>

      {showModal && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content py-0 px-0">
              <div className="modal-header modelheader">
                <h5 className="modal-title modaltitle">Add New Type</h5>
                <button
                  type="button"
                  className="btn-close btnclose"
                  onClick={() => {
                    setShowModal(false);
                    setNewOption('')
                    setSelectedDependencies([]); // Reset selected dependencies
                  }}
                >
                  <span>×</span>
                </button>
              </div>
              <form onSubmit={handleAddOption}>
                <div className="modal-body model-popupPad pb-0">
                  <div className="form-group">
                    <label className='formlabel text-left w-100'>Name</label>
                    <input
                      type="text"
                      className="form-control fromFilespop"
                      placeholder="Enter new type"
                      value={newOption}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <div className="dropdown">
                      {/* Dropdown Toggle Button */}
                      <button
                        className="dropdown-toggle w-100 text-start despselect"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent accidental closure
                          setIsDropdownOpen(!isDropdownOpen);
                        }}
                      >
                        {selectedDependencies.length
                          ? selectedDependencies.map((dep) => dep.type).join(', ')
                          : 'Select Dependencies'}
                      </button>

                      {/* Dropdown Menu */}
                      {isDropdownOpen && (
                        <ul className="dropdown-menu show">
                          {availableDependencies.map((dependency, index) => {
                            const isChecked = selectedDependencies.includes(dependency);
                            return (
                              <li
                                key={index}
                                className="dropdown-item"
                                onClick={() => handleDependencySelect(dependency)} // Click anywhere to toggle
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={`dependency-${index}`}
                                  checked={isChecked}
                                  onChange={() => handleDependencySelect(dependency)}
                                  onClick={(e) => e.stopPropagation()} // Prevents double toggling
                                />
                                <label
                                  htmlFor={`dependency-${index}`}
                                  className="form-check-label"
                                >
                                  {dependency.type}
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
                <div className="text-center mt3-mb2">
                  <button
                    type="button"
                    className="btn CancelBtn"
                    onClick={() => {
                      setShowModal(false);
                      setNewOption('');
                      setSelectedDependencies([]); // Reset selected dependencies
                    }}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn saveBtn">
                    Add New Type
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GenericSetup;
