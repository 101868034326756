import React, { useState, useEffect } from 'react';
import { GETDROPDOWNDATA, DLTDEPENDENTDATA, GETDEPENDENCIES, GETDEPENDENCYVALUES, SAVEDEPENDENTDROPDOWNVALUES } from './UniversityServices';
import deleteicon from '../../img/delete.png';

const CrudOperations = ({ selectedType }) => {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ name: '', status: true });
  const [columns,setColumns] = useState([]);
  const [dependencyValues, setDependencyValues] = useState({});

  const fetchData = async () => {
    try {
      const payload = { type: selectedType };
      const response = await GETDROPDOWNDATA(payload);
      if(response.data.status == 'failure') {
        alert(response.data.message);
      } else {
        if (response.data) {
          const res = response.data.data;
          setItems(res);
          setTotalItems(res.length);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getDependencies = async () => {
    try {
      const payload = { type: selectedType };
      const response = await GETDEPENDENCIES(payload);
      if(response.data.status == 'failure') {
        alert(response.data.message);
      } else {
        if (response.data) {
          console.log("response.data.data",response.data.data)
          setColumns(response.data.data);
          
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getDependencyValues = async () => {
    try {
      const payload = { type: selectedType };
      const response = await GETDEPENDENCYVALUES(payload);
      if(response.data.status == 'failure') {
        alert(response.data.message);
      } else {
        if (response.data) {
          console.log("response.data.data",response.data.data)
          setDependencyValues(response.data.data);
          
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(()=> {
    getDependencyValues();
  },[showModal]);


  useEffect(() => {
    fetchData();
    getDependencies();
  }, [selectedType]);


  const handleDependencyChange = (e, field) => {
    const { value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

 

  const handleDelete = async (name) => {
    try {
      const payload = { type: selectedType, name:name};
      const response = await DLTDEPENDENTDATA(payload);
      if (response.data.status === "success") {
        fetchData();
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'status' ? value === 'true' : value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const isValid = Object.keys(dependencyValues).every(field => {
      return formData[field] !== undefined && formData[field] !== '';
    });

    if (!isValid) {
      alert('Please fill all mandatory fields.');
      return;
    }
    const payload = {
      ...formData,
      type: selectedType,
      logged_in_user: sessionStorage.getItem('user'),
    };

    try {
        const response = await SAVEDEPENDENTDROPDOWNVALUES(payload);
        if (response.data.status === "success") {
          fetchData();
          setShowModal(false);
          setFormData({ name: '', status: true });
        } else {
          alert(response.data.message);
        }
      
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);



  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const capitalizedType = selectedType.charAt(0).toUpperCase() + selectedType.slice(1);

  return (
    <div className="mt-4">
      <div className="d-flex justify-content-between mb-3 AdinTitle">
        <h3>Manage {capitalizedType}</h3>
        <button
          className="btn addBtnpls"
          onClick={() => {
            setShowModal(true);
            setFormData({ name: '', status: true });
          }}
        >
          Add {capitalizedType}
        </button>
      </div>
          
      <table className="application-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Status</th>
            {columns.length > 0 && columns.map((col, idx) => (
              <th key={idx}>{col}</th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={index}>
              <td>{indexOfFirstItem + index + 1}</td>
              <td>{item.name}</td>
              <td>{item.status ? 'Active' : 'Inactive'}</td>
              {columns.length > 0 && columns.map((col, idx) => (
        <td key={idx}>{item[col] || '-'}</td>  
      ))}
              <td>
                <img
                  src={deleteicon}
                  alt="Delete Icon"
                  style={{ width: "23px", marginLeft: "10px" }}
                  onClick={() => handleDelete(item.name)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {items.length === 0 && <p>No Data found.</p>}
      {items.length > itemsPerPage && (
        <div className="pagination">
          {pageNumbers.map(number => (
            <button
              key={number}
              onClick={() => paginate(number)}
              className={` ${currentPage === number ? 'active' : ''}`}
            >
              {number}
            </button>
          ))}
        </div>
     )}

      {showModal && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content py-0 px-0">
              <div className="modal-header modelheader">
                <h5 className="modal-title modaltitle">
                  {`Add ${capitalizedType}`}
                </h5>
                <button
                  type="button"
                  className="btn-close btnclose"
                  onClick={() => setShowModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <form onSubmit={handleFormSubmit}>
                <div className="modal-body model-popupPad pb-0">
                  <div className="form-group">
                    <label className='formlabel text-left w-100'>Name<span style={{ color: 'red' }}>*</span></label>
                    <input
                      type="text"
                      className="form-control fromFilespop"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <label className='formlabel text-left w-100'>Status</label>
                    <select
                      className="form-control fromFilespop droparrow-select"
                      name="status"
                      value={formData.status}
                      onChange={handleInputChange}
                    >
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </div>
                  {Object.keys(dependencyValues).map((field, idx) => (
                    <div className="form-group mt-3" key={idx}>
                      <label className='formlabel text-left w-100'>{field}<span style={{ color: 'red' }}>*</span></label>
                      <select
                        className="form-control fromFilespop droparrow-select"
                        name={field}
                        value={formData[field] || ''}
                        onChange={(e) => handleDependencyChange(e, field)}
                      >
                        <option value="">Select {field}</option>
                        {Object.entries(dependencyValues[field][0]).map(([key, value]) => (
                          <option key={key} value={key}>{value}</option>
                        ))}
                      </select>
                    </div>
                  ))}
                </div>
                <div className="text-center mt3-mb2">
                  <button
                    type="button"
                    className="btn previousbtn"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn saveBtn">
                    {'Save'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CrudOperations;
