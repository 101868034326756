import React, { useState, useEffect } from "react"; 
import { GETCOURSES, ADDCOURSES, GETSEATS, DELETECOURSE, EDITCOURSES } from "./AdminServices"; // API Methods
import { FaEdit, FaTrash } from "react-icons/fa";
import { UNIVERSITY_CODE } from "../config";
import "./FeeStructure.css";

const FeeStructure = () => {
  const [rows, setRows] = useState([]);
  const [formData, setFormData] = useState({
    courseType: "",
    course: "",
    branch: "",
    seats: "",
    amount: "",
    university_code: UNIVERSITY_CODE,
    logged_in_user: sessionStorage.getItem('user')
  });
  const [courseData, setCourseData] = useState({});
  const [seatsAvailable, setSeatsAvailable] = useState(0);
  const [editIndex, setEditIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [editFormData, setEditFormData] = useState({
    courseType: "",
    course: "",
    branch: "",
    seats: "",
    amount: "",
  });

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await GETCOURSES();
        if (response.data.status === "success") {
          setCourseData(response.data.message);
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching course data:", error);
      }
    };

    fetchCourses();
  }, []);

  useEffect(() => {
    const fetchSeats = async () => {
      try {
        const response = await GETSEATS({ university_code: UNIVERSITY_CODE });

        setRows(response.data.data);
      } catch (error) {
        console.error("Error fetching seat data:", error);
      }
    };

    fetchSeats();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "courseType" ? { course: "", branch: "" } : {}),
    }));
  };

  
  const getCoursesForType = (selectedType) => {
    return selectedType && courseData[selectedType]
      ? courseData[selectedType].courses || []
      : [];
  };

 
  const getBranchesForCourse = (selectedType, selectedCourse) => {
    return courseData[selectedType]?.branches?.[selectedCourse] || [];
  };

  const handleAddOrUpdateRow = async () => {
    const { courseType, course,branch, seats, amount, university_code , logged_in_user } = formData;

    if (!courseType || !course || !branch || !seats || !amount) {
      alert("Please fill all fields.");
      return;
    }

    try {
      const response = await ADDCOURSES({
        courseType,
        course, 
        branch,
        seats,
        amount,
        university_code,
        logged_in_user
      });

      if (response?.data?.status == "success") {
        let updatedRows =
          editIndex !== null
            ? [...rows]
            : [...rows, { courseType, course, branch,seats, amount }];

        if (editIndex !== null) {
          updatedRows[editIndex] = { courseType, course, branch,seats, amount };
        }

        setRows(updatedRows);
        setEditIndex(null);
        alert("Data saved successfully!");
      } else {
        alert("Error saving data: " + response?.data?.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      alert("Failed to save data.");
    }

    setFormData({ courseType: "", course: "", branch:"",seats: "", amount: "" });
  };

  const handleEditRow = (index) => {
    setEditFormData(rows[index]);
    setEditIndex(index);
    setIsModalOpen(true);
  };
  
 
  const handleSaveChanges = async () => {
    if (
        !editFormData.courseType ||
        !editFormData.course ||
        !editFormData.branch ||
        
        !editFormData.seats ||
        !editFormData.amount
      ) {
        alert("Please fill all fields.");
        return;
      }
  
    try {
      const payload = { ...editFormData, university_code:UNIVERSITY_CODE };
      
      const response = await EDITCOURSES(payload);
      if (response?.data?.status === "success") {
        const updatedRows = [...rows];
        updatedRows[editIndex] = payload;
        setRows(updatedRows);
        setIsModalOpen(false);
        alert("Course updated successfully!");
      } else {
        alert("Error updating course: " + response?.data?.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      alert("Failed to update course.");
    }
  };

  const handleDeleteRow = async (index) => {
    const rowToDelete = rows[index];
    
    try {
     
      const response = await DELETECOURSE({
        
        course: rowToDelete.course,
        branch: rowToDelete.branch,
        seats : rowToDelete.seats,
        university_code: UNIVERSITY_CODE,
      });

      if (response?.data?.status === "success") {
        const updatedRows = rows.filter((_, i) => i !== index);
        setRows(updatedRows);
        alert("Course deleted successfully.");
      } else {
        alert("Failed to delete course: " + response?.data?.message);
      }
    } catch (error) {
      console.error("API Error:", error);
      alert("Error deleting course.");
    }
  };


  return (
    <div className="BxAllSide px-0 py-0">
      <div className="BxApple p-6 AdinTitle">
        <h1>Fee Structure</h1>

        <div className="form-container">
          <select
            name="courseType"
            value={formData.courseType}
            onChange={handleChange}
            className="form-select"
          >
            <option value="">Select Course Type</option>
            {Object.keys(courseData).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>

          <select
            name="course"
            value={formData.course}
            onChange={handleChange}
            className="form-select"
            disabled={!formData.courseType}
          >
            <option value="">Select Course</option>
            {getCoursesForType(formData.courseType).map((course) => (
              <option key={course} value={course}>
                {course}
              </option>
            ))}
          </select>
          <select
  name="branch"
  value={formData.branch}
  onChange={handleChange}
  className="form-select"
  disabled={!formData.course}
>
  <option value="">Select Branch</option>
  {courseData[formData.courseType]?.branches?.[formData.course]?.map((branch) => (
    <option key={branch} value={branch}>{branch}</option>
  ))}
</select>


          <input
            type="number"
            name="seats"
            value={formData.seats}
            onChange={handleChange}
            placeholder="Enter Seats"
            className="form-input"
          />

          <input
            type="number"
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            placeholder="Enter Amount"
            className="form-input"
          />

          <button onClick={handleAddOrUpdateRow} className="btn add-row-btn">
            Add Row
          </button>
        </div>
  
        <h2>Fee Structure Table</h2>
        <div className="table-responsive">
          <table className="formtable">
            <thead>
              <tr>
                <th>Course Type</th>
                <th>Course</th>
                <th>Branch</th>
                <th>No of Seats</th>
                <th>Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  <td>{row.courseType}</td>
                  <td>{row.course}</td>
                  <td>{row.branch}</td>
                  <td>{row.seats}</td>
                  <td>{row.amount}</td>
                  <td>
                    <button
                      className="btn-icon edit-btn"
                      onClick={() => handleEditRow(index)}
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="btn-icon delete-btn"
                      onClick={() => handleDeleteRow(index)}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Modal for Edit Row */}
      {isModalOpen && (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Fee Structure</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setIsModalOpen(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSaveChanges}>
                  <div className="mb-3">
                    <label className="form-label">Course Type</label>
                    <input
                      type="text"
                      name="courseType"
                      value={editFormData.courseType}
                      onChange={(e) =>
                        setEditFormData({
                          ...editFormData,
                          courseType: e.target.value,
                        })
                      }
                      className="form-control"
                      required
                      readOnly
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Course</label>
                    <input
                      type="text"
                      name="course"
                      value={editFormData.course}
                      onChange={(e) =>
                        setEditFormData({
                          ...editFormData,
                          course: e.target.value,
                        })
                      }
                      className="form-control"
                      required
                      readOnly
                  
                    />
                  </div>
                  
                  <div className="mb-3">
                     <label className="form-label">Branch</label>
                     <input
                       type="text"
                       name="branch"
                       value={editFormData.branch}
                       onChange={(e) =>
                       setEditFormData({
                         ...editFormData,
                        branch: e.target.value,
         })
    }
    className="form-control"
    required
    readOnly
  />
</div>

                  <div className="mb-3">
                    <label className="form-label">No of Seats</label>
                    <input
                      type="number"
                      name="seats"
                      value={editFormData.seats}
                      onChange={(e) =>
                        setEditFormData({
                          ...editFormData,
                          seats: e.target.value,
                        })
                      }
                      className="form-control"
                      required
                      readOnly
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Amount</label>
                    <input
                      type="number"
                      name="amount"
                      value={editFormData.amount}
                      onChange={(e) =>
                        setEditFormData({
                          ...editFormData,
                          amount: e.target.value,
                        })
                      }
                      className="form-control"
                      required
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Save Changes
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeeStructure;