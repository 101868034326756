import React, { useEffect, useState } from "react";
import { GETAFFILIATIONAMOUNT, PAYMENTGATEWAYLIST, INITIATETRANSACTION } from './PaymentServices'
import { useNavigate } from "react-router-dom";
import Process from "./Process";

const ProceedPayment = (application_id) => {
  const affiliationCode = sessionStorage.getItem("affiliation_code");
  const [feeamount, setFeeAmount] = useState(0);
  const [gateways, setGateways] = useState([]);
  const [transamount, setTransAmount] = useState(0);
  const [selectedGateway, setSelectedGateway] = useState(""); // State for selected payment gateway
  const navigate = useNavigate();

  const handlePaymentInitiation = async () => {
    if (!selectedGateway) {
      alert("Please select a payment gateway.");
      return;
    }

    // Navigate to Process component and pass data
    navigate("/process", { state: { selectedGateway, amount: feeamount + transamount } });
  };

  useEffect(() => {
    const fetchAmount = async () => {
      const response = await GETAFFILIATIONAMOUNT(application_id);
      const initiate_response = await PAYMENTGATEWAYLIST();
      setGateways(initiate_response.data.message);
      setFeeAmount(response.data.message.amount);
      setTransAmount(response.data.message.transaction_charges);
    };
    fetchAmount();
  }, [affiliationCode]);

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Online Payment Instructions</h2>
      <ul style={styles.instructions}>
        <li>The payment will be processed by a 3rd party payment gateway.</li>
        <li>
          Click <strong>"Pay Now"</strong> to proceed for online payment. You can pay the amount using debit card /
          net banking / credit card.
        </li>
        <li>
          After the completion of the online transaction, you will be redirected to this portal, from where you can
          take a printout of the application.
        </li>
      </ul>

      <div style={styles.feeDetails}>
        <div style={styles.feeRow}>
          <span>Application Fee:</span>
          <span style={styles.bold}>₹{feeamount}</span>
        </div>
        <div style={styles.feeRow}>
          <span>Transactional Charges:</span>
          <span style={styles.bold}>₹ {transamount}</span>
        </div>
        <div style={styles.feeRow}>
          <span>Amount to Pay:</span>
          <span style={styles.bold}>₹ {parseInt(feeamount) + parseInt(transamount)} </span>
        </div>
      </div>

      <h3 style={styles.subheading}>Select the payment gateway.</h3>

      <div style={styles.gatewayContainer}>
        {gateways.map((gateway, index) => (
          <label style={styles.gatewayOption} key={index}>
            <input
              type="radio"
              name="gateway"
              value={gateway.pg_code}
              onChange={(e) => setSelectedGateway(e.target.value)} // Update selectedGateway state
            />
            <span style={styles.gatewayText}>
              <img
                src={gateway.icon || ""}
                // alt={gateway.pg_name || "Payment Gateway"}
                style={styles.gatewayIcon}
              />
              {gateway.pg_name}
            </span>
          </label>
        ))}
      </div>

      <button style={styles.payButton} onClick={handlePaymentInitiation}>
        Pay Now
      </button>

      <p style={styles.warning}>
        Please do not press the back or refresh button of the browser during the whole transaction.
      </p>
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "'Arial', sans-serif",
    width: "80%",
    margin: "20px auto",
    padding: "20px",
   
  },
  heading: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  instructions: {
    listStyleType: "disc",
    marginLeft: "20px",
    marginBottom: "20px",
  },
  feeDetails: {
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "10px",
    margin: "0px auto",
    marginBottom: "20px",
    backgroundColor: "#f9f9f9",
    width:"50%"
  },
  feeRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  bold: {
    fontWeight: "bold",
  },
  subheading: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  gatewayContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  gatewayOption: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  gatewayText: {
    marginLeft: "10px",
  },
  gatewayIcon: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  payButton: {
    padding: "10px",
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
    margin: "0 auto",
    display: "flex",
    padding: "10px 20px",
    border: "none"
  },
  warning: {
    marginTop: "20px",
    textAlign: "center",
    color: "red",
    fontSize: "14px",
  },
};

export default ProceedPayment;