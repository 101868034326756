import React, { useState, useEffect, useRef } from 'react';
import { GETCOURSES, ADDCOURSESTABLE, GETCOURSESTABLE, UPDATECOURSE, DELETECOURSEMAPPING } from './AdminServices';
import './Courses.css';
import { UNIVERSITY_CODE } from '../config';


function CourseTable() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [editdrop,setEditDrop] = useState(false);
  const university_code = UNIVERSITY_CODE;
  const [courseData, setCourseData] = useState({});
  const [existingData, setExistingData] = useState([]);
  const [rows, setRows] = useState([{ courseType: '', course: '', branches: [] }]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedCourseData, setSelectedCourseData] = useState(null);
  const [editIndex, setEditIndex] = useState(null);


  const dropdownRef = useRef(null);
  const editdropdownRef = useRef(null);

  function useOutsideAlerter(ref, closeFunction) {
    useEffect(() => {
      function handleOutsideClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeFunction();
        }
      }
      document.addEventListener("click", handleOutsideClick);
      return () => document.removeEventListener("click", handleOutsideClick);
    }, [ref, closeFunction]);
  }

  useOutsideAlerter(dropdownRef, () => setIsDropdownOpen(false));
  useOutsideAlerter(editdropdownRef, () => setEditDrop(false));


  const handleInputChange = (e, index, field) => {
    setRows(prevRows => {
      const newRows = [...prevRows];
      if (field === 'branches') {
        const branch = e.target.value;
        const isChecked = e.target.checked;

        if (isChecked) {
          // Add branch if checked
          newRows[index][field] = [...newRows[index][field], branch];
        } else {
          // Remove branch if unchecked
          newRows[index][field] = newRows[index][field].filter(b => b !== branch);
        }
      } else {
        newRows[index][field] = e.target.value;
        if (field === 'courseType') {
          newRows[index].course = '';
          newRows[index].branches = [];
        } else if (field === 'course') {
          newRows[index].branches = [];
        }
      }
      return newRows;
    });
  };


  const saveAndContinue = async () => {
    try {
      const processedRows = rows.map(row => ({
        courseType: row.courseType,
        course: row.course,
        branches: (row.branches && row.branches.length > 0) ? row.branches : [row.course], // Ensure branch is the same as course if empty or undefined
      }));

      const payload = {
        university_code,
        courses: processedRows,
      };

      console.log("Sending Payload:", JSON.stringify(payload, null, 2));

      const response = await ADDCOURSESTABLE(payload);
      if (response.data.status === 'success') {
        alert('Course added successfully!');
        setRows([{ courseType: '', course: '', branches: [] }]);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error saving course:', error);
      alert('Failed to save the course.');
    }
  };


  const handleDelete = async (index) => {
    const selectedCourse = existingData[index];

    const confirmDelete = window.confirm(`Are you sure you want to delete the course "${selectedCourse.course}"?`);
    if (!confirmDelete) return;

    try {
      const payload = {
        university_code,
        course: selectedCourse.course,
      };

      console.log("Deleting Course Payload:", JSON.stringify(payload, null, 2));

      const response = await DELETECOURSEMAPPING(payload);

      if (response.data.status === 'success') {
        alert('Course deleted successfully!');
        setExistingData(prevData => prevData.filter((_, i) => i !== index));
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error deleting course:', error);
      alert('Failed to delete the course.');
    }
  };

  // Open Edit Modal with Selected Course Data
  const handleEditClick = (index) => {
    const selectedData = existingData[index];

    setSelectedCourseData({
      ...selectedData,
      branches: Array.isArray(selectedData.branches)
        ? selectedData.branches
        : selectedData.branches.split(', '), // Ensure branches are in array format
    });

    setEditIndex(index);
    setIsEditModalOpen(true);
  };
  


  // Handle Save Changes from Modal
  const handleSaveChanges = async () => {
    try {
      setEditDrop(false);
      const updatedBranches = Array.isArray(selectedCourseData.branches) && selectedCourseData.branches.length > 0
        ? selectedCourseData.branches
        : [selectedCourseData.course]; // If branches are empty, use the course as branch

      const payload = {
        university_code,
        courses: [
          {
            courseType: selectedCourseData.courseType,
            course: selectedCourseData.course,
            branches: updatedBranches,
          },
        ],
      };

      console.log("Sending Payload:", JSON.stringify(payload, null, 2));

      const response = await UPDATECOURSE(payload);

      if (response.data.status === 'success') {
        alert('Course updated successfully!');
        setExistingData(prevData => {
          const updatedData = [...prevData];
          updatedData[editIndex] = { ...selectedCourseData, branches: updatedBranches };
          return updatedData;
        });
        setIsEditModalOpen(false);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error updating course:', error);
      alert('Failed to update the course.');
    }
  };

  // Handle Input Change in Edit Modal
  const handleModalInputChange = (e, field) => {
    const value = e.target.value;
    setSelectedCourseData((prevData) => {
      const updatedBranches = e.target.checked
        ? [...prevData.branches, value]  // Add branch if checked
        : prevData.branches.filter((branch) => branch !== value);  // Remove branch if unchecked

      return {
        ...prevData,
        [field]: updatedBranches,
      };
    });
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await GETCOURSES();
        if (response.data.status === 'success') {
          setCourseData(response.data.message);
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    

    const fetchTableCourse = async () => {
      try {
        const response = await GETCOURSESTABLE({ university_code });
        if (response.data.status === 'success') {
          const courseMessage = response.data.message;
          const mappedData = Object.entries(courseMessage).flatMap(([courseType, details]) =>
            details.courses.map((course) => ({
              courseType,
              course,
              branches: details.branches[course]?.join(', ') || '',
            }))
          );
          setExistingData(mappedData);
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching existing course table data:', error);
      }
    };

    fetchCourses();
    fetchTableCourse();
  }, [rows,handleEditClick]);



  return (
    <div className='BxApple p-6'>
      <div className='AdinTitle mb-4'>
        <h2>Course Information</h2>
      </div>


      <div className='mb-4'>
      <table border="1" className='formtable'>
        <thead>
          <tr>
            <th>Course Type</th>
            <th>Course</th>
            <th>Branches</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                <select className='all-dropselect' value={row.courseType} onChange={(e) => handleInputChange(e, index, 'courseType')}>
                  <option value="">Select</option>
                  {Object.keys(courseData).map((type, i) => (
                    <option key={i} value={type}>{type}</option>
                  ))}
                </select>
              </td>
              <td>
                <select className='all-dropselect'
                  value={row.course}
                  onChange={(e) => handleInputChange(e, index, 'course')}
                  disabled={!row.courseType}
                >
                  <option value="">Select</option>
                  {courseData[row.courseType]?.courses.map((course, i) => (
                    <option key={i} value={course}>{course}</option>
                  ))}
                </select>
              </td>
              <td ref={dropdownRef}>
                <div style={{
                  position: 'relative',
                  width: '100%',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  padding: '8px',
                  backgroundColor: '#fff',
                }}
                >
                  <button
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    backgroundColor: '#f9f9f9',
                    border: 'none',
                    padding: '8px',
                    cursor: 'pointer',
                    borderRadius: '4px',
                  }}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  disabled={!row.course}
                  ref={dropdownRef}
                >Select Branches</button>
                { isDropdownOpen && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: '0',
                      right: '0',
                      maxHeight: '150px',
                      overflowY: 'auto',
                      backgroundColor: '#fff',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      padding: '8px',
                      marginTop: '4px',
                    }}
                  >
                  {courseData[row.courseType]?.branches?.[row.course]?.map((branch, i) => (
                      <label key={i} style={{ display: 'flex' }} >
                      <input
                        type="checkbox"
                        value={branch}
                        checked={row.branches.includes(branch)}
                        onChange={(e) => handleInputChange(e, index, 'branches')}
                        style={{width:"10%"}}
                      />
                      {branch}
                    </label>
                  ))}
                  </div>)}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <div className='mb-4 text-right'>
      <button className='btn saveBtn' onClick={saveAndContinue} >
        Save and Continue
      </button>
      </div>


      <h3>Saved Courses</h3>
      <table border="1" className='formtable'>
        <thead>
          <tr>
            <th>Course Type</th>
            <th>Course</th>
            <th>Branches</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {existingData.map((row, index) => (
            <tr key={index}>
              <td>{row.courseType}</td>
              <td>{row.course}</td>
              <td>{row.branches || row.course}</td>
              <td>
                <button onClick={() => handleEditClick(index)}>Edit</button>
                <button onClick={() => handleDelete(index)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isEditModalOpen && (
        <div className="model-overlay">
          <div className="model-content">
            <h2>Edit Course</h2>
            <button
          onClick={() => setEditDrop(!editdrop)}
          style={{
            position: "absolute",
            background: "none",
            border: "none",
            fontSize: "20px",
            cursor: "pointer",
            marginLeft: "300px",
            marginTop: "-85px",
          }}
          className="btn-close btnclose"
        >
          ×
        </button>
            <div>
              <label>Course Type</label>
              <select
                value={selectedCourseData?.courseType}
                // onChange={(e) => handleModalInputChange(e, 'courseType')}
                disabled
              >
                <option value="">Select</option>
                {Object.keys(courseData).map((type, i) => (
                  <option key={i} value={type}>{type}</option>
                ))}
              </select>
            </div>
            <div>
              <label>Course</label>
              <select
                value={selectedCourseData?.course}
                // onChange={(e) => handleModalInputChange(e, 'course')}
                disabled
              >
                <option value="">Select</option>
                {courseData[selectedCourseData?.courseType]?.courses.map((course, i) => (
                  <option key={i} value={course}>{course}</option>
                ))}
              </select>
            </div>
            <div>
              <label >Branches</label>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  padding: '8px',
                  backgroundColor: '#fff',
                }}
                ref={editdropdownRef}
              >

                <button
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    border: 'none',
                    padding: '8px',
                    cursor: 'pointer',
                    borderRadius: '4px',
                  }}
                  onClick={() => setEditDrop(!editdrop)} // Toggle dropdown visibility
                >Select Branches
                </button>


                {(editdrop &&
                  <div
                    style={{
                      position: 'absolute',
                      top: '100%',
                      left: '0',
                      right: '0',
                      maxHeight: '150px',
                      overflowY: 'auto',
                      backgroundColor: '#fff',
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      padding: '8px',
                      marginTop: '4px',
                    }}
                  >
                    {courseData[selectedCourseData?.courseType]?.branches?.[selectedCourseData?.course]?.map((branch, i) => (
                      <label key={i} style={{ display: 'flex' }}>
                        <input
                          type="checkbox"
                          value={branch}
                          checked={selectedCourseData?.branches?.includes(branch)}
                          onChange={(e) => handleModalInputChange(e, 'branches')}
                          style={{ marginRight: '8px', width:'10%' }}
                        />
                        {branch}
                      </label>
                    ))}
                  </div>
                )}
              </div>
            </div>


            <button onClick={handleSaveChanges} style={{ backgroundColor: '#FF485B', color: 'white' }}>
              Save Changes
            </button>
            <button onClick={() => {setIsEditModalOpen(false);}}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseTable;